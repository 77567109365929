import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./wheel.css";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import Com_Login from "../../components/login/Com_Login";
import Com_Register from "../../components/login/Com_Register";
import back_tex from "../../img/backtext.webp";
import Wheel_Jocker from "../../components/wheel/Wheel_Jocker";

const Wheel = () => {
  const { qr, value, promotion_name } = useParams();
  const [isSpinning, setIsSpinning] = useState(false);
  const [currentDegree, setCurrentDegree] = useState(0);
  const [winningItem, setWinningItem] = useState(null);
  const [isStopping, setIsStopping] = useState(false);
  const [spinCount, setSpinCount] = useState(0);
  const intervalRef = useRef(null);
  const decelerationRef = useRef(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [msg, setMsg] = useState();
  const [error, setError] = useState(null);
  const [code_one, setCode_one] = useState();
  const [spinCode, setSpinCode] = useState(value || "");
  const { user } = useContext(AuthContext);
  const userName = user?.details?.name;
  const [showBtn, setShowBtn] = useState(false);
  const [winItem, setWinItem] = useState("");

    const handleWinItemChange = (newWinItem) => {
      setWinItem(newWinItem);
      console.log("Win Item received from Wheel:", newWinItem);
      // Optionally, you can update the API or perform other actions here
    };

   const places = [
     "Mobile Phone",
     "1 Carton Free",
     "Fuel Vouchers",
     "2 Carton Free",
     "Power Bank",
     "1 Carton Free",
     "Glrocery Vouchers",
     "1 Carton Free",
   ];


  const handleShowBtn = () => {
    setShowBtn(!showBtn);
  };

    const devApiLink = "http://localhost:4348/api/v1";
    const apiLink = "https://xl-api.notionspromos.com/api/v1";

 

  const saveGift = async () => {
    const userEmail = user.details?.email;

    if (!user) {
      return;
    } 
    if (winningItem === "Try again"){
      return;
    }
     try {
       await axios.put(`${apiLink}/winningItem/${winningItem}`, {
         email: userEmail,
         promotion_name: promotion_name,
         user_name: userName,
       });

       setApiResponse("Thank for Participation");
       // console.log("Your code is verified");
     } catch (err) {
       setError("Please enter a valid code", err);
     }
     
  };

  useEffect(() => {
    if (isSpinning || isStopping) {
      document.getElementById(
        "wheel_in"
      ).style.transform = `rotate(${currentDegree}deg)`;
    }
  }, [currentDegree, isSpinning, isStopping]);

  //! save wining item in DB

  useEffect(() => {
    if (!winningItem) {
      return;
    }
    saveGift();
  }, [currentDegree, isSpinning, isStopping, winningItem, user]);

  return (
    <div className="wrap_main">
      <div className="wrap_main_in">
        <div className="heder">
          {!user ? (
            <div className="wheel_form">
              <h2>Spin Wheel</h2>
              <br />

              <div className="wheel_form_hed">
                {!showBtn ? <h3>Login</h3> : <h3>Register</h3>}
              </div>

              {/* <small> If you have account already login</small> */}
              {!showBtn ? <Com_Login /> : <Com_Register />}
              {/* <Com_Login /> */}

              {!showBtn && (
                <small>
                  {" "}
                  If you dont have account{" "}
                  <em onClick={handleShowBtn}>register</em>{" "}
                </small>
              )}
              {showBtn && (
                <small>
                  {" "}
                  If you have account <em onClick={handleShowBtn}>
                    Login
                  </em>{" "}
                </small>
              )}
              <img src={require("../../img/login_jok.png")} alt="" />
            </div>
          ) : (
            <div className="spin_wheel">
              <h2>
                {" "}
                Welcome, {userName}!
                <br />
              </h2>
              <br />
              {/* <img src={require("../../img/Jocker.png")} alt="" /> */}
              <br />

              {apiResponse ? (
                <>
                  <h2>Thank for Participation</h2>
                  <a href="https://xlcrisp.com/"> Explorer more win more</a>
                </>
              ) : (
                <>
                  <small>
                    You can now spin the wheel and win exciting prizes. Good
                    luck!
                  </small>
                </>
              )}
            </div>
          )}
        </div>

        <div>
          <Wheel_Jocker items={places} onWinItemChange={handleWinItemChange} />
        </div>
      </div>
    </div>
  );
};

export default Wheel;
