import React from 'react'
import "./ddfLandingPage.scss";
import { Link } from 'react-router-dom';

const DDFLandingPage = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="ddf_wrp">
          <div className="desktop">
            <img src={require("../../img/promo-home-banner.jpg")} alt="" />
          </div>
          <div className="code_section">
            <h1>
              {" "}
              <em>Welcome ! </em> to Win a Trip to Dubai
            </h1>

            <Link to={"/"}>
              <button>Enter Code Here</button>
            </Link>
          </div>
          <div className="mobile">
            <img src={require("../../img/promo-home-banner-sm.jpg")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DDFLandingPage
